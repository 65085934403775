<template>
    <div class="receipt">
        <div class="container">
            <div class="loading" v-if="!isContentReady">
                <loader-content :isDisplay="true"></loader-content>
            </div>

            <order-receipt :orderDetail="orderDetail" v-if="isContentReady"></order-receipt>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import OrderService from '@/services/orderService';
import GTMService from '@/services/gtmService';
import OrderReceipt from '@/components/order/Receipt';
import RouterPath from '@/router/path';

export default {
	components: {
		OrderReceipt
	},
	data() {
		return {
			isContentReady: false,
			orderDetail: null
		};
	},
	mounted() {
        this.getContent();
    },
	methods: {
		async getContent() {
            this.isContentReady = false;

			const param = {
				id: this.$route.params.id
			};
            const result = await OrderService.getOrderDetail(param);

			if (result.data) {
				this.orderDetail = result.data;
				this.isContentReady = true;

				// GTM Purchase
				this.gtmPurchase();

			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}
        },
		async gtmPurchase() {
			const urlParts = location.pathname.split('/');

			if (!urlParts.includes('my')) {
                const result = await GTMService.validateGTMPurchase(this.orderDetail.orderNumber);

                if (!result.data.isTracked) {
                    GTMService.gtmPurchase(
                        this.$gtm,
                        this.orderDetail.orderNumber,
                        this.orderDetail.finalPrice,
                        this.orderDetail.includeVatPrice !== '-' ? this.orderDetail.includeVatPrice : '',
                        this.orderDetail.orderType === Constants.ORDER_TYPE.DELIVERY ? this.orderDetail.deliveryData.price : '',
                        this.setGTMPurchaseItems()
                    );

                    // Mark flag to prevent reload page
                    GTMService.trackGTMPurchase(this.orderDetail.orderNumber);
                }
			}
		},
		setGTMPurchaseItems() {
            const items = [];

            this.orderDetail.products.forEach((item) => {
                const productId = parseInt(item.param.split('.')[0]);
                const storeId = this.orderDetail.storeData.param.split('.')[0];
                let type = '';

                switch (this.orderDetail.orderType) {
                    case Constants.ORDER_TYPE.SERVICE:
                        type = 'install';
                        break;

                    case Constants.ORDER_TYPE.DELIVERY:
                        type = 'delivery';
                        break;
                }

                items.push({
                    item_id: productId,
                    item_name: item.fullName,
                    item_brand: item.brand,
                    item_category: type,
                    item_category2: item.sellerProductId,
                    item_category3: storeId,
                    discount: item.totalDiscount ? parseFloat(item.totalDiscount?.replace(/,/g, '')) : 0,
                    price: item.totalPrice ? parseFloat(item.totalPrice?.replace(/,/g, '')) : 0,
                    quantity: item.quantity
                });
            });

            return items;
        },
	},
	metaInfo() {
		const title = 'ใบนัดหมายติดตั้ง #' + this.$route.params.id;
		const desc = 'ใบนัดหมายติดตั้งกับร้านค้า YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
                { vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
                { vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
.receipt {
	padding: 20px;
	margin: 30px 0;

	.loading {
		height: 500px;
	}
}
</style>